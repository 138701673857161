<template>
  <div class="banks-account-page">
    <v-row justify="center" align="center">
      <v-col>
        <div class="text-center">
          <vuetify-logo />
        </div>
        <h2>أرقام الحسابات البنكيه</h2>
        <div class="card">
          <div v-for="(item, i) in allBanks" :key="i" class="item-info">
            <p class="title">{{ item.name }}</p>
            <p class="dec">{{ item.number }}</p>
            <p>SA {{ item.iban }}</p>
            <hr />
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ServiceFactory } from "../services/ServiceFactory";
const service = ServiceFactory.get("bank");
import VuetifyLogo from "../components/VuetifyLogo.vue";
export default {
  data: () => ({
    dataLoading: false,
    allBanks: [],
  }),
  components: {
    VuetifyLogo,
  },
  created() {
    this.fetchAllItems();
  },
  methods: {
    async fetchAllItems() {
      this.dataLoading = true;
      const data = await service.getAllBanks();
      this.allBanks = data;
      this.dataLoading = false;
    },
  },
};
</script>

<style>
.banks-account-page {
  text-align: center;
}
.banks-account-page h2 {
  margin: 50px 0 30px 0;
  text-align: center;
  color: #614c37 !important;
  font-size: 15px !important;
}
.banks-account-page .card {
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.18);
  margin: auto;
  background: #fff;
  border-radius: 20px;
  padding: 15px;
  margin-bottom: 30px;
}
.banks-account-page .card p {
  font-size: 14px !important;
}
.banks-account-page .card .title {
  color: #34c334;
  font-family: "Frutiger LT Arabic 75 Black" !important;

  letter-spacing: 1px !important;
  font-weight: normal;
}
</style>